import { useNavigate } from "react-router-dom";
import { useRef, useEffect, useState, useContext } from "react";
import { ReactComponent as OrderIcon } from "../../assets/order-icon.svg";
import  {ReactComponent as LogoutIcon} from "../../assets/icons/other_icons/logout-icon.svg";
import NewSmartsaLogo from "../../assets/smartsa-icon-mobile.svg"
import DefaultUserIcon from "../../assets/DefaultUserIcon.svg"
import { ReactComponent as HelpIcon } from "../../assets/icons/other_icons/help-icon.svg";
import styles from "./ToolbarComponent.module.css";
import axios from "axios";
import ReactGA from "react-ga4"
import { ReactComponent as HowItWorksIcon } from "../../assets/how-it-works-icon.svg"
import { deleteIndexedDB } from "../../utils/indexDButils.js";
import { ReactComponent as LabSeoIcon } from "../../assets/labs_seo.svg"
import { ReactComponent as LearnSeoIcon } from "../../assets/learn_seo.svg"
import { ReactComponent as OurStoryIcon } from "../../assets/ourstory_seo.svg"
import  { ReactComponent as BlogsIcon } from "../../assets/blogs_seo.svg"
import { ReactComponent as AboutUsIcon } from "../../assets/about-us-icon.svg"
import { DarkModeContext } from "../../context/DarkMode";
import { ReactComponent as TrademarkIcon } from "../../assets/trademark_icon.svg"

const ToolbarComponent = ({ closeToolbar }) => {
  const navigate = useNavigate();
  const toolbarRef = useRef();
  const userId = localStorage.getItem("userId")
  const token = localStorage.getItem("token")
  const [profileData, setProfileData] = useState(null);
  const level = localStorage.getItem("level");
  const {darkMode}=useContext(DarkModeContext)
  const IndexDB_NAME=process.env.REACT_APP_INDEXDB_NAME
const Index_DB_store_name=process.env.REACT_APP_INDEXDB_STORE_NAME
const levelColors = {
  2: darkMode?"var(--primary-color-lvl-2)":"var(--lgt-primary-color-lvl-2)", // Level 2 color = GREEN
  3: darkMode?"var(--primary-color-lvl-3)":"var(--lgt-primary-color-lvl-3)", // Level 3 color = YELLOW
  4: darkMode?"var(--primary-color-lvl-4)":"var(--lgt-primary-color-lvl-4)", // Level 4 color = RED
  5: darkMode?"var(--primary-color-lvl-5)":"var(--lgt-primary-color-lvl-5)", // Level 5 color = #FF9900
};
const changeColor = levelColors[level] || (darkMode?"var(--primary-color-lvl-1)":"var(--lgt-primary-color-lvl-1)");

// Handle clicks outside the toolbar to close it
  const handleClickOutside = (event) => {
    if (toolbarRef.current && !toolbarRef.current.contains(event.target)) {
      closeToolbar();
    }
  };

// Fetch user profile data from the API
  const fetchProfileData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/profile/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const fetchedProfileData = response.data.data;

      if (fetchedProfileData.length === 0) {
        console.error("Profile data is empty");
        setProfileData([]);
      } else {
        setProfileData(fetchedProfileData);
      }
    } catch (error) {
      console.error("Error fetching Profile data: ", error);
    }
  };

  useEffect(() => {
    fetchProfileData() // Fetch profile data on component mount
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

// Handle navigation to different paths
  const handleLinkClick = (path) => {
    navigate(path);
    closeToolbar();
  };

// Function to handle user logout
  const logoutHandler=async()=>{
    try {
      const response=await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/logout`)
    } catch (error) {
      console.error(error);
      
    }
  }

// Handle logout and navigate to the home page
  const handleLogout = async(path) => {
    ReactGA.event({
      category:"Click",
      action:"Logout Clicked",
      label:"Logout Button Clicked"
    });
    deleteIndexedDB(IndexDB_NAME);
    const isTourVisited = localStorage.getItem("isTourVisited");
    const isThankYouPageNotVisited = localStorage.getItem("isThankYouPageNotVisited");
    localStorage.clear();
    if(isTourVisited !== null) localStorage.setItem("isTourVisited", isTourVisited);
    if(isThankYouPageNotVisited !== null) localStorage.setItem("isThankYouPageNotVisited", isThankYouPageNotVisited);
    await logoutHandler()
    navigate(path);
  };

  return (
    <div className={styles.wrapper} ref={toolbarRef}>
      <>
        <div className={styles.sidenavContainer}>
          <div className={styles.sidenavHeader}>
            <div style={{fontSize: '1rem'}}>
              Hi, {profileData?.[0]?.username}
            </div>
            <img
              loading="lazy"
              src={NewSmartsaLogo}
              className={styles.smartsaIcon}
              alt="icon"
            />
          </div>
          <div className={styles.separator} />
          <div className={styles.sidenavLinks}>
            <img
              loading="lazy"
              src={profileData?.[0]?.profileImageURL?profileData?.[0]?.profileImageURL:DefaultUserIcon}
              className={styles.profileIcon}
              style={{ height: "1.5625rem", width: "1.5625rem", borderColor: profileData?.[0]?.profileImageURL?changeColor:"none",
                border:profileData?.[0]?.profileImageURL?"1px solid":"none" }}
              alt="pfp"
            />
            <div onClick={() => handleLinkClick(`/profile/${userId}`)}>Profile</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === `/profile/${userId}` ? changeColor : darkMode ? "#333333" : "#dee2e6"}} />
          <div className={styles.sidenavLinks}>
            <OrderIcon fill={darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"} className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/orders")}>My Orders</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === "/orders" ? changeColor : darkMode ? "#333333" : "#dee2e6"}}/>
          <div className={styles.sidenavLinks}>
            <AboutUsIcon fill={darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"} className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/aboutus")}>About Us</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === "/aboutus" ? changeColor : darkMode ? "#333333" : "#dee2e6"}}/>
          <div className={styles.sidenavLinks}>
            <HowItWorksIcon fill={darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"} className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/how-it-works")}>How it Works</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === "/how-it-works" ? changeColor : darkMode ? "#333333" : "#dee2e6"}}/>
          <div className={styles.sidenavLinks}>
            <OurStoryIcon fill={darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"} className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/ourStory")} >Our Story</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === "/ourStory" ? changeColor : darkMode ? "#333333" : "#dee2e6"}}/>
          <div className={styles.sidenavLinks}>
            <BlogsIcon fill={darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"} className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/blogs")}>Blogs</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/blogs") ? changeColor : darkMode ? "#333333" : "#dee2e6"}}/>
          <div className={styles.sidenavLinks}>
            <LearnSeoIcon fill={darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"} className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/learn")}>Learn</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/learn") ? changeColor : darkMode ? "#333333" : "#dee2e6"}}/>
          <div className={styles.sidenavLinks}>
            <LabSeoIcon fill={darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"} className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/lab-page")}>Labs</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/lab-page") ? changeColor : darkMode ? "#333333" : "#dee2e6"}}/>
          <div className={styles.sidenavLinks}>
            <HelpIcon fill={darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"} className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/help")} >Contact Us</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === "/help" ? changeColor : darkMode ? "#333333" : "#dee2e6"}}/>
          <div className={styles.sidenavFooter}>
              <LogoutIcon fill={darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"} className={styles.sidenavIcon}/>
              <div onClick={() => handleLogout("/")}>Logout</div>
          </div>
          <div className={styles.separator} style={{backgroundColor: darkMode? "#333333" : "#dee2e6"}}/>
          <div className={styles.privacyPolicy}>
            <a href="/terms" style={{color: darkMode ? '#cccccc' : '#444648', textDecoration: 'none'}}>Terms & Conditions</a> | <a href="/privacy" style={{color: darkMode ? '#cccccc' : '#444648', textDecoration: 'none'}}>Privacy Policy</a>
          </div>
          <div>
            <span
              style={{
                fontSize: '0.625rem',
                color: darkMode ? '#cccccc' : '#444648', textDecoration: 'none',
                position: 'absolute',
                bottom: '0px',
                margin: '0 0.75rem 1.25rem 0.75rem'
              }}
            >
              <TrademarkIcon style={{fill: darkMode && '#ccc'}} /> SMARTSA Tech Pvt. Ltd. All Rights reserved
            </span>
          </div>
          </div>
      </>
    </div>
  );
};

export default ToolbarComponent;
