import React, { useContext } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { DarkModeContext } from '../../context/DarkMode'

const Loader = ({height=100,paddingLeft=0,paddingRight=0,marginRight=0,marginLeft=0}) => {
  const {darkMode}=useContext(DarkModeContext)
  const level=Number(localStorage.getItem("level"));
  const levelColors = {
    2: darkMode?"var(--primary-color-lvl-2)":"var(--lgt-primary-color-lvl-2)", // Level 2 color = GREEN
    3: darkMode?"var(--primary-color-lvl-3)":"var(--lgt-primary-color-lvl-3)", // Level 3 color = YELLOW
    4: darkMode?"var(--primary-color-lvl-4)":"var(--lgt-primary-color-lvl-4)", // Level 4 color = RED
    5: darkMode?"var(--primary-color-lvl-5)":"var(--lgt-primary-color-lvl-5)", // Level 5 color = #FF9900
  };
  const changeColor = levelColors[level] || (darkMode?"var(--primary-color-lvl-1)":"var(--lgt-primary-color-lvl-1)");

  console.log(levelColors[level]);
  return (
    <Box sx={{
    
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `${height}vh`,
    width:'100%',
    paddingLeft:`${paddingLeft}rem`,
    paddingRight:`${paddingRight}rem`,
    marginLeft:`${marginLeft}rem`,
    marginRight:`${marginRight}rem`,
    
      }}>
      <CircularProgress size="8vw" style={{color:levelColors[level]}} />
      </Box>
  )
}

export default Loader