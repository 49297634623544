import React, { useContext, useState } from "react";
import styles from "./UnauthNav.module.css";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { ClickAwayListener, FormControlLabel, IconButton, Switch } from "@mui/material";
import { ReactComponent as NewSmartsaLogo } from "../../assets/smartsa_new_logo.svg";
import { ReactComponent as LabSeoIcon } from "../../assets/labs_seo.svg"
import { ReactComponent as LearnSeoIcon } from "../../assets/learn_seo.svg"
import { ReactComponent as OurStoryIcon } from "../../assets/ourstory_seo.svg"
import  { ReactComponent as BlogsIcon } from "../../assets/blogs_seo.svg"
import { ReactComponent as HelpIcon } from "../../assets/icons/other_icons/help-icon.svg";
import { ReactComponent as HowItWorksIcon } from "../../assets/how-it-works-icon.svg"
import { ReactComponent as AboutUsIcon } from "../../assets/about-us-icon.svg"
import { DarkModeContext } from '../../context/DarkMode';
import { ReactComponent as LightModeIcon } from '../../assets/LightModeToggleIcon.svg';
import { ReactComponent as DarkModeIcon } from '../../assets/DarkModeToggleIcon.svg';
import { ReactComponent as SmartsaTextLogo } from '../../assets/smartsa_text_logo.svg';
import { ReactComponent as SmartsaIcon } from "../../assets/smartsa-icon-mobile.svg"
import { ReactComponent as TrademarkIcon } from "../../assets/trademark_icon.svg"

const UnauthNav = () => {
  const {darkMode,toggleDarkMode}=useContext(DarkModeContext);
  const [open, setOpen] = useState(false);
  const level=localStorage.getItem("level")
  const levelColors = {
    2: darkMode?"var(--primary-color-lvl-2)":"var(--lgt-primary-color-lvl-2)", // Level 2 color = GREEN
    3: darkMode?"var(--primary-color-lvl-3)":"var(--lgt-primary-color-lvl-3)", // Level 3 color = YELLOW
    4: darkMode?"var(--primary-color-lvl-4)":"var(--lgt-primary-color-lvl-4)", // Level 4 color = RED
    5: darkMode?"var(--primary-color-lvl-5)":"var(--lgt-primary-color-lvl-5)", // Level 5 color = #FF9900
  };
  const changeColor = levelColors[level] || (darkMode?"var(--primary-color-lvl-1)":"var(--lgt-primary-color-lvl-1)");
  const onMenuClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  console.log(open);

  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.leftContainer}>
          <div className={styles.menuButtonContainer}>
            <IconButton
              style={{
                backgroundColor: darkMode && "#000",
                color: "#ffffff",
                padding: "0",
              }}
              onClick={onMenuClick}
              size="small"
              color="default"
            >
              <MenuIcon sx={{fill: !darkMode && 'var(--lgt-bg-color-6)'}} fontSize="small" />
            </IconButton>
          </div>

          <Link to={"/"}>
            <NewSmartsaLogo className={styles.img} fill={`${darkMode && 'white'}`} />
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <NavLink to={"/signup"} style={{textDecoration: 'none', color: changeColor}}>Signup</NavLink>
          <NavLink to={"/help"} style={{textDecoration: 'none', color: changeColor}}>Help?</NavLink>
          <FormControlLabel onClick={toggleDarkMode} style={{marginRight: '0.4rem', width: '1.8rem'}} control={
            darkMode ? <DarkModeIcon /> : <LightModeIcon />
          }  
        />
        </div>
      </div>

      {open && (
        <>
          <div className={styles.overlay} onClick={onClose}/>
        <ClickAwayListener onClickAway={onClose}>
          <div
            className={`${styles.MenuContainer} ${!darkMode && 'MenuContainer-light-mobile'} ${open ? styles.isOpen : ""}`}
          >
            <div className={styles.menuLogoContaier}>
              <Link to={"/"}>
                <SmartsaTextLogo className={styles.img1} fill={`${darkMode ? '#ffffff' : '#000000'}`} />
              </Link>
              <div>
                <SmartsaIcon style={{height: '1.875rem', width: '1.25rem'}}/>
              </div>
            </div>
            <ul>
            <li key={3} style={{ borderBottom: window.location.pathname.startsWith("/aboutus") ? `2px solid #44ccff` :  darkMode? "1px solid #333" : '2px solid var(--lgt-tertiary-bg-color)'}}>
              <AboutUsIcon fill={`${darkMode ? "#cccccc":"var(--lgt-bg-color-6)"}`}/>
                <NavLink to={"/aboutus"} className={({ isActive }) => (isActive ? styles.activeLink : undefined)}>About Us</NavLink>
              </li>
              <li key={5} style={{ borderBottom: window.location.pathname.startsWith("/how-it-works") ? `2px solid #44ccff` :  darkMode? "1px solid #333" : '2px solid var(--lgt-tertiary-bg-color)'}}>
                <HowItWorksIcon fill={`${darkMode ? "#cccccc":"var(--lgt-bg-color-6)"}`}/>
                <NavLink to={"/how-it-works"} className={({ isActive }) => (isActive ? styles.activeLink : undefined)}>How it Works</NavLink>
              </li>
              <li key={4} style={{ borderBottom: window.location.pathname.startsWith("/OurStory") ? `2px solid #44ccff` :  darkMode? "1px solid #333" : '2px solid var(--lgt-tertiary-bg-color)'}}>
                <OurStoryIcon fill={`${darkMode ? "#cccccc":"var(--lgt-bg-color-6)"}`}/>
                <NavLink to={"/OurStory"} className={({ isActive }) => (isActive ? styles.activeLink : undefined)}>Our Story</NavLink>
              </li>
              <li key={7} style={{ borderBottom: window.location.pathname.startsWith("/blogs") ? `2px solid #44ccff` :  darkMode? "1px solid #333" : '2px solid var(--lgt-tertiary-bg-color)'}}>
                <BlogsIcon fill={`${darkMode ? "#cccccc":"var(--lgt-bg-color-6)"}`}/>
                <NavLink to={"/blogs"} className={({ isActive }) => (isActive ? styles.activeLink : undefined)}>Blogs</NavLink>
              </li>
              <li key={1} style={{ borderBottom: window.location.pathname.startsWith("/learn") ? `2px solid #44ccff` :  darkMode? "1px solid #333" : '2px solid var(--lgt-tertiary-bg-color)'}}>
                <LearnSeoIcon fill={`${darkMode ? "#cccccc":"var(--lgt-bg-color-6)"}`}/>
                <NavLink to={"/learn"} >Learn</NavLink>
              </li>
              <li key={2} style={{ borderBottom: window.location.pathname.startsWith("/lab-page") ? `2px solid #44ccff` :  darkMode? "1px solid #333" : '2px solid var(--lgt-tertiary-bg-color)'}}>
                <LabSeoIcon fill={`${darkMode ? "#cccccc":"var(--lgt-bg-color-6)"}`}/>
                <NavLink to={"/lab-page"} >Labs</NavLink>{" "}
              </li>
              <li key={6} style={{ borderBottom: window.location.pathname.startsWith("/help") ? `2px solid #44ccff` :  darkMode? "1px solid #333" : '2px solid var(--lgt-tertiary-bg-color)'}}>
                <HelpIcon fill={`${darkMode ? "#cccccc":"var(--lgt-bg-color-6)"}`}/>
                <NavLink to={"/help"} className={({ isActive }) => (isActive ? styles.activeLink : undefined)}>Contact Us</NavLink>
              </li>
            </ul>
            <div
              style={{
                margin: '1rem 0 0 2rem',
                fontSize: '0.75rem'
              }}
            >
              <a href="/terms" style={{color: darkMode ? '#cccccc' : '#444648', textDecoration: 'none'}}>Terms & Conditions</a> | <a href="/privacy" style={{color: darkMode ? '#cccccc' : '#444648', textDecoration: 'none'}}>Privacy Policy</a>
            </div>
            <div style={{position: 'absolute', bottom: '0px', margin: '0 0.75rem 1.25rem 0.75rem'}}>
              <span style={{ fontSize: '0.625rem', color: darkMode ? '#cccccc' : '#444648'  }}>
                <TrademarkIcon /> SMARTSA Tech Pvt. Ltd. All Rights reserved
              </span>
            </div>
          </div>
        </ClickAwayListener>
        </>
      )}
    </>
  );
};

export default UnauthNav;
