import React, { useContext } from 'react';
import styles from './FooterMobile.module.css';
import { ReactComponent as InstagramIcon } from '../../assets/icons/FooterIcons/InstagramIcon.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/FooterIcons/LinkedInIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/FooterIcons/YoutubeIcon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/FooterIcons/FacebookIcon.svg';
import { ReactComponent as BackToTopArrowIcon } from '../../assets/icons/FooterIcons/BackToTopArrowIcon.svg';
import NewSmartsaLogo from "../../assets/smartsa-icon-mobile.svg";
import { ReactComponent as CallIcon} from "../../assets/icons/other_icons/call-icon.svg";
import { ReactComponent as MailIcon} from "../../assets/icons/other_icons/email-icon.svg";
import { ReactComponent as SmartsaFooterMobileIcon } from "../../assets/icons/FooterIcons/SmartsaFooterMobileIcon.svg"
import { DarkModeContext } from '../../context/DarkMode';

const FooterMobile = () => {
  const {darkMode}=useContext(DarkModeContext);
  const level=localStorage.getItem("level")
  const levelColors = {
    2: darkMode?"var(--primary-color-lvl-2)":"var(--lgt-primary-color-lvl-2)", // Level 2 color = GREEN
    3: darkMode?"var(--primary-color-lvl-3)":"var(--lgt-primary-color-lvl-3)", // Level 3 color = YELLOW
    4: darkMode?"var(--primary-color-lvl-4)":"var(--lgt-primary-color-lvl-4)", // Level 4 color = RED
    5: darkMode?"var(--primary-color-lvl-5)":"var(--lgt-primary-color-lvl-5)", // Level 5 color = #FF9900
  };
  const changeColor = levelColors[level] || (darkMode?"var(--primary-color-lvl-1)":"var(--lgt-primary-color-lvl-1)");
  return (
    <div className={styles.footerContainer}>
      <hr style={{ border: 'none', height: '1px', color: '#333', backgroundColor: darkMode ? '#333':'var(--lgt-tertiary-bg-color)' }} />
      <div className={styles.footerColumn} style={{marginLeft: '1.5rem'}}>
        {/* <img src={SmartsaFooterMobileIcon} alt="Logo" style={{width: '130px', height: '27px'}} /> */}
        <SmartsaFooterMobileIcon fill={`${darkMode && 'white'}`} style={{ width: '130px', height: '27px'}} />
        <div style={{fontSize: '1.5rem'}}>Math is not a Subject,</div>
        <div style={{fontSize: '1.5rem'}}>Math is a <span style={{ color: changeColor }}>Skill!</span></div>
      </div>

      <div className={`${styles.footerColumn} ${!darkMode && 'footerContainer-light'}`} style={{marginLeft: '1.5rem', gap: '0.4rem !important'}}>
        <p>Reach out to us</p>
        <div style={{fontSize: '0.75rem', marginTop: '-0.75rem'}}>Get all your learning questions answered with SMARTSA!</div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {/* <img src={CallIcon} alt="Call Icon" className={styles.footerIcons} /> */}
          <CallIcon className={styles.footerIcons} style={{fill: '#44ccff'}} />
          <a href='tel:+917620444739' type='phone' style={{ color: darkMode ? '#cccccc' : '#444648'}}>&#43;91 7620444739</a>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {/* <img src={MailIcon} alt="Mail Icon" className={styles.footerIcons} /> */}
          <MailIcon className={styles.footerIcons} style={{width: '15px', height: '15px', fill: '#44ccff'}} />
          <a href="mailto:support@smartsa.in" type='email' style={{ color: darkMode ? '#cccccc' : '#444648'}}>support@smartsa.in</a>
        </div>
      </div>

      <div className={`${styles.footerHead} ${!darkMode && 'footerContainer-light'}`}>
        <div className={styles.footerColumn}>
          <p>Company</p>
          <a href="/" style={{ color: darkMode ? '#cccccc' : '#444648'}}>Home</a>
          <a href="/#aboutus" style={{ color: darkMode ? '#cccccc' : '#444648'}}>About Us</a>
          <a href="/OurStory" style={{ color: darkMode ? '#cccccc' : '#444648'}}>Our Story</a>
          <a href="/how-it-works" style={{ color: darkMode ? '#cccccc' : '#444648'}}>How It Works</a>
        </div>
        <div className={styles.footerColumn} style={{ color: darkMode ? '#cccccc' : '#444648'}}>
          <p>Resources</p>
          <a href="/learn" style={{ color: darkMode ? '#cccccc' : '#444648'}}>Learn</a>
          <a href="/lab-page" style={{ color: darkMode ? '#cccccc' : '#444648'}}>Labs</a>
          <a href="/blogs" style={{ color: darkMode ? '#cccccc' : '#444648'}}>Blogs</a>
        </div>
      </div>
      <div className={styles.socialsContainer}>
        <div className={styles.followUs}>Follow Us</div>
        <div className={styles.subFooterRight}>
          <a href="https://www.instagram.com/smartsa.in?igsh=Y3gxbzY0c2QyZ3d2" target='_blank'>
            <InstagramIcon fill={`${darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"}`} />
          </a>
          <a href="https://www.youtube.com/@smartsa9603" target='_blank'>
            <YoutubeIcon fill={`${darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"}`} />
          </a>
          <a href="https://www.linkedin.com/company/smartsa-india/" target='_blank'>
            <LinkedInIcon fill={`${darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"}`} />
          </a>
          <a href="https://m.facebook.com/profile.php/?id=100086846304635&name=xhp_nt__fb__action__open_user" target='_blank'>
            <FacebookIcon fill={`${darkMode?"var(--bg-color-8)":"var(--lgt-bg-color-6)"}`} />
          </a>
        </div>
      </div>
      <div style={{display: 'flex', gap: '1.5vw', alignItems: 'center',justifyContent: 'center', fontSize: '3.5vw', margin: '3rem auto 1rem auto'}}>
        <a href="/terms" style={{color: '#7c7c7c', textDecoration: 'none'}} target='_blank' rel='noopener noreferrer'>Terms & Conditions</a>  
        <div>&#124;</div>
        <a href="/privacy" style={{color: '#7c7c7c', textDecoration: 'none'}} target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
      </div>
      <hr style={{ border: 'none', height: '1px', color: '#333', backgroundColor: darkMode ?'#333':'var(--lgt-tertiary-bg-color)', position: 'relative' }} />
      <div className={styles.subFooter}>
        <div>&#169; 2024 SMARTSA Tech Pvt. Ltd. All right reserved</div> 
      </div>
    </div>
  )
}

export default FooterMobile