
import axios from "axios"


export const initializeInterceptor=()=>{
    axios.interceptors.response.use(
        (response)=>{
          return response
          
        },
        (err)=>{
          if ((err.response.status===401 && err.response.data.message==="Unauthorized") || (err.response.status===403 && err.response.data.message==="Forbidden") ){
            localStorage.clear()
           window.location.href="/"
           return;
          }
          return Promise.reject(err)
        }
      )
}

