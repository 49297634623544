import React, { Suspense, useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import { Outlet, Route, useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import LevelUpMobile from "../LevelUp/LevelUpMobile";
import LevelDownAudio from "../../assets/Sounds/level_down.mp3";

const ProtectedRoute = () => {
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : null;
  // console.log(decodedToken);

  const isAuthenticated = token && decodedToken;
  const navigate = useNavigate();
  const [hasLeveledDown, setHasLevelledDown] = useState(false);
  const [popup, setPopup] = useState("");
  // console.log(hasLeveledDown);

  // Function to check if the user has leveled down.
  const checkLeveldown = async () => {
    if (isAuthenticated) { // Proceed only if the user is authenticated.
      const LevelDownAudioObj = new Audio(LevelDownAudio);
      const userId = localStorage.getItem("userId");
      try {
        // Fetch user level data from the server.
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/popups/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(response.data.data[0]);
        if (response.data.data.length === 0) {
          throw new Error("No User level data found");
        }
        const level = response.data.data[0].level;
        localStorage.setItem("level", level);
        const notCompletedStreak = response.data.data[0].not_completed_streak;
        const hasSeenLd =  response.data.data[0].has_seen_ld;
        // console.log(notCompletedStreak, hasSeenLd);

        // Check if the user should see the level-down popup.
        if (
          notCompletedStreak !== 0 &&
          notCompletedStreak % 3 === 0 &&
          hasSeenLd === 0
        ) {
          setHasLevelledDown(true);
          LevelDownAudioObj.play();
          const name = localStorage.getItem("username");
          setPopup(
            <LevelUpMobile
              currLevel={level}
              prevLevel={9999}
              name={name}
              setShowDialog={setHasLevelledDown}
            />
          );
          // Update the server to mark the level-down popup as viewed.
          const putResponse = await axios.patch(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/popups/${userId}`
          );
          if (!putResponse.data.message) {
            throw new Error("Error updating has user viewed level down");
          }
        }
      } catch (error) {
        console.log("Error fetching user level data for popup", error);
      }
    }
  };

// Function to handle user logout.
  const logoutHandler = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/logout`
      );
    } catch (error) {
      console.error(error);
    }
  };

  // Effect to handle authentication and level-down check on component mount.
  useEffect(() => {
    if (!isAuthenticated) {
      logoutHandler();
      return navigate("/");
    }
    checkLeveldown();
  }, []);

  return (
    <>
      {hasLeveledDown && <Suspense fallback={<Loader />}>{popup}</Suspense>}
      {!hasLeveledDown && (
        <>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </>
      )}
    </>
  );
};

export default ProtectedRoute;
