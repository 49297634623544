import { jwtDecode } from "jwt-decode";
import React, { Suspense, useEffect } from "react";
import { Outlet, Route, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

const AllRounderRoutes = () => {
  return (
    <>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      
    </>
  );
};



export default AllRounderRoutes