import { createContext, useContext, useEffect, useState } from "react";

// Create a context for managing dark mode
const DarkModeContext=createContext();

// DarkModeContextProvider component to wrap around the app and provide dark mode functionality
const DarkModeContextProvider=({children})=>{
    const [darkMode,setDarkmode]=useState(localStorage.getItem('Mode') ? JSON.parse(localStorage.getItem('Mode')) : true);


    // useEffect(() => {
    //     const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
    //     setDarkmode(prefersDarkMode);
    //   }, []);
    
      // useEffect to add or remove the "dark-mode" class from the body element based on the darkMode state
      useEffect(() => {
        if (darkMode) {
          document.body.classList?.add("dark-mode");
        } else {
          document.body.classList?.remove("dark-mode");
        }
      }, [darkMode]);

    // Function to toggle dark mode and save the preference to localStorage
    const toggleDarkMode=()=>{
        setDarkmode(prev=>{
            localStorage.setItem("Mode", !darkMode);
            return !prev});
    }

    return(
        <DarkModeContext.Provider value={{darkMode,toggleDarkMode}}>
          <div className={darkMode?"dark-text":"light-text"}>
            {children}
            </div>
        </DarkModeContext.Provider>
    )
}

export {DarkModeContext,DarkModeContextProvider}